import Image from "next/image"
import {ImageLoader, ImagesVersion} from "../../config"
import Link from "next/link"

export default function Blog(props) {

	let blogs = [
		{
			"link": "",
			"cover": "https://bs-uploads.toptal.io/blackfish-uploads/components/blog_post_page/content/cover_image_file/cover_image/1285492/regular_1708x683_Untitled-e7fde53f1e5631a8728cc9aefc1538e8.png",
			"heading": "وضعیت فعلی بیت‌کوین مانند قیمت 5 هزار دلاری در سال پیش است",
			"meta_description": "پیش‌بینی کف بازار ارز های دیجیتال تقریباً غیرممکن است؛ اما معامله‌گران می‌توانند با استفاده از چند شاخص مهم چند شاخص مهم چند شاخص مهم چند شاخص مهم چند شاخص مهم و کاربردی پایان بازار نزولی را حدس بزنند.",
			"published_at": "31 خرداد 1402",
		},
		{
			"link": "",
			"cover": "https://bs-uploads.toptal.io/blackfish-uploads/components/blog_post_page/content/cover_image_file/cover_image/1285492/regular_1708x683_Untitled-e7fde53f1e5631a8728cc9aefc1538e8.png",
			"heading": "وضعیت فعلی بیت‌کوین مانند قیمت 5 هزار دلاری در سال پیش است",
			"meta_description": "پیش‌بینی کف بازار ارز های دیجیتال تقریباً غیرممکن است؛ اما معامله‌گران می‌توانند با استفاده از چند شاخص مهم چند شاخص مهم چند شاخص مهم چند شاخص مهم چند شاخص مهم و کاربردی پایان بازار نزولی را حدس بزنند.",
			"published_at": "31 خرداد 1402",
		},
		{
			"link": "",
			"cover": "https://bs-uploads.toptal.io/blackfish-uploads/components/blog_post_page/content/cover_image_file/cover_image/1285492/regular_1708x683_Untitled-e7fde53f1e5631a8728cc9aefc1538e8.png",
			"heading": "وضعیت فعلی بیت‌کوین مانند قیمت 5 هزار دلاری در سال پیش است",
			"meta_description": "پیش‌بینی کف بازار ارز های دیجیتال تقریباً غیرممکن است؛ اما معامله‌گران می‌توانند با استفاده از چند شاخص مهم چند شاخص مهم چند شاخص مهم چند شاخص مهم چند شاخص مهم و کاربردی پایان بازار نزولی را حدس بزنند.",
			"published_at": "31 خرداد 1402",
		},
		{
			"link": "",
			"cover": "https://bs-uploads.toptal.io/blackfish-uploads/components/blog_post_page/content/cover_image_file/cover_image/1285492/regular_1708x683_Untitled-e7fde53f1e5631a8728cc9aefc1538e8.png",
			"heading": "وضعیت فعلی بیت‌کوین مانند قیمت 5 هزار دلاری در سال پیش است",
			"meta_description": "پیش‌بینی کف بازار ارز های دیجیتال تقریباً غیرممکن است؛ اما معامله‌گران می‌توانند با استفاده از چند شاخص مهم چند شاخص مهم چند شاخص مهم چند شاخص مهم چند شاخص مهم و کاربردی پایان بازار نزولی را حدس بزنند.",
			"published_at": "31 خرداد 1402",
		},
	]

	return (
		<section>
			<div className="container">
				<h2 className="h4 text-center fw-bold">وبلاگ و آموزش ارز های دیجیتال</h2>
				<div className="py-3"></div>
				<div className="row d-flex justify-content-center">
					{
						blogs.map(function(item, index){
							return (
								<div key={index} className="blog-item-layer col-12 col-sm-6 col-md-6 col-lg-3 mt-2 mb-3">
									<div className="w-100 h-100 d-flex flex-column blog-item-box c-transition">
										<Link href={item.link}>
											<div className="d-flex flex-column">
												<Image loader={ImageLoader} width={900} height={510} objectFit="cover" loading="lazy" src={item.cover + "?v=" + ImagesVersion} alt={item.heading}/>
												<div className="py-3 px-3">
													<div className="d-flex mb-2 justify-content-between">
														<div style={{backgroundColor: "var(--background-color)"}} className="d-flex justify-content-center align-items-center px-3 py-2 blog-item-category">{item.published_at}</div>
													</div>
													<h3 className="h6 fw-bold" style={{textAlign:"justify"}}>{item.heading}</h3>
													<p className="mb-0 blog-item-description" style={{textAlign:"justify"}}>{item.meta_description}</p>
												</div>
											</div>
										</Link>
									</div>
								</div>
							)
						})
					}
				</div>
				<div className="py-3"></div>
				<div className="d-flex justify-content-center align-items-center">
					<div className="d-inline-block px-4 py-2 c-transition design-primary-btn">
						<Link href={""}>
							<a style={{textDecoration:"none", color:"white"}}>موارد بیشتر</a>
						</Link>
					</div>
				</div>
			</div>
		</section>
	)
}
