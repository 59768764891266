import Image from "next/image"
import {CoinsServerIconsFolderUrl, CoinsServerImagesFolderUrl, FormatPrice, GenerateSvgChartQueryString, ImageLoader, ImagesVersion, IsExist, RoundPercent, UserCoinFavorites} from "../../config"

export default function CurrencyItem(props) {
	const isFavoriteCoin = props.userFavoriteCoins.includes(props.item.coinID)
	return (
		<div className="cryptocurrency-info-layer col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-2 mb-3" data-index={props.index + 1}>
			<div id={"cryptocurrency-item-layer" + props.item.coinID} className="cryptocurrency-item-layer c-transition w-100 px-3 py-3 d-flex flex-column cryptocurrency-info-box position-relative" data-price={(props.item.lastPriceIrt / props.item.priceIrtUnit)} data-id={props.item.coinID} data-icon={CoinsServerIconsFolderUrl + "/coins/" + props.item.symbol + ".png?v=" + ImagesVersion} data-namefa={props.item.nameFa} data-nameen={props.item.nameEn} data-symbol={props.item.symbol} data-styletype={"color"}>
				<div className="cryptocurrency-info-icon-box">
					<div className="w-100 h-100 position-relative">
						<Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={CoinsServerIconsFolderUrl + "/coins/" + props.item.symbol + ".png?v=" + ImagesVersion} alt={"آیکون " + props.item.nameFa}/>
					</div>
				</div>
				<div className="d-flex flex-row">
					<div className="d-flex flex-row align-items-center w-100">
						<div className="d-flex justify-content-center align-items-center">
							<div id={"favorite-item-icon-" + props.item.coinID} onClick={e=>UserCoinFavorites(props.item.nameFa ,props.item.coinID, props.router)} data-state={isFavoriteCoin ? "on" : ""} className="d-block p-0 favorite-item-icon">
								<svg className="d-block w-100 h-100" viewBox="0 0 512 512"><path fill="CurrentColor" d="M0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84.02L256 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 .0003 232.4 .0003 190.9L0 190.9z"/></svg>
							</div>
						</div>
						<div className="d-flex flex-row justify-content-center align-items-center px-1">
							<div className="px-2">{props.item.nameFa}</div>
							<div className="pt-1 sub-text-size">({props.item.symbol})</div>
						</div>
					</div>
				</div>
				<div>
					<span className="px-1 sub-text-size">قیمت جهانی:</span>
					<span id={"coin-" + props.item.coinID + "-item-usdt-price"} className="px-1 fw-bold">{FormatPrice(props.item.lastPriceUsdt)}</span>
					<span className="sub-text-size">تتر</span>
				</div>
				<div>
					<span className="px-1 sub-text-size">قیمت:</span>
					<span id={"coin-" + props.item.coinID + "-item-irt-price"} className="px-1 fw-bold">{FormatPrice(props.item.lastPriceIrt / props.item.priceIrtUnit)}</span>
					<span className="sub-text-size">تومان</span>
				</div>
				<div className="my-1 d-flex justify-content-center align-items-center">
					<div className="cryptocurrency-info-chart-img position-relative my-1" style={{height:"75px"}}>
						<Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={CoinsServerImagesFolderUrl + "/charts/coins7d/irt/" + props.item.symbol + ".svg" + "?v=" + GenerateSvgChartQueryString()} alt={"نمودار قیمت " + props.item.nameFa}/>
					</div>
				</div>
				<div className="d-flex align-items-center">
					<span className="px-1 sub-text-size">24 ساعت گذشته:</span>
					{
						IsExist(props.item.priceUsdtChangePercent24H) ? 
						<>
							<span id={"coin-" + props.item.coinID + "-item-usdt-price-change-percent-box"} style={{direction:"ltr", color: IsExist(props.item.priceUsdtChangePercent24H) ? props.item.priceUsdtChangePercent24H.toString().includes("-") ? "var(--red)" : "var(--green)" : "var(--green)"}}>
								<span id={"coin-" + props.item.coinID + "-item-usdt-price-change-percent"}>{RoundPercent(props.item.priceUsdtChangePercent24H)}</span><span> %</span>
							</span>
							<div id={"coin-" + props.item.coinID + "-item-usdt-price-change-percent-arrow-box"} className="d-block p-0 px-1 d-inline-block cryptocurrency-info-change-icon" style={{color: IsExist(props.item.priceUsdtChangePercent24H) ? props.item.priceUsdtChangePercent24H.toString().includes("-") ? "var(--red)" : "var(--green)" : "var(--green)"}}>
								<svg id={"coin-" + props.item.coinID + "-item-usdt-price-change-percent-arrow-bottom"} className={(IsExist(props.item.priceUsdtChangePercent24H) ? (props.item.priceUsdtChangePercent24H.toString().includes("-") ? "d-block" : "d-none") : "d-none") + " " + "w-100 h-100"} width="24" height="24" viewBox="0 0 24 24"><path fill="CurrentColor" d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"></path></svg>
								<svg id={"coin-" + props.item.coinID + "-item-usdt-price-change-percent-arrow-top"} className={(IsExist(props.item.priceUsdtChangePercent24H) ? (props.item.priceUsdtChangePercent24H.toString().includes("-") ? "d-none" : "d-block") : "d-none") + " " + "w-100 h-100"} width="24" height="24" viewBox="0 0 24 24"><path fill="CurrentColor" d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19z"></path></svg>
							</div>
						</>
						:
						""
					}
				</div>
			</div>
		</div>
	)
}
