import Image from "next/image"
import {ImageLoader, ImagesVersion} from "../config"

export default function Comment(props) {
	return (
		<div className="comments-item-layer">
			<div className="d-flex flex-column w-100 h-100 p-2 comments-item-box">
				<div>
					<div className="forom-item-category-icon mb-2 p-1 position-relative">
						<Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={props.src + "?v=" + ImagesVersion} alt={props.title}/>
					</div>
					<h3 className="h6 mb-1">{props.title}</h3>
					<p className="mb-2 comments-item-question" style={{textAlign:"justify"}}>
						{props.description}
						<a className="px-1" href={props.href}>مشاهده</a>
					</p>
					{
						props.tags.map(function(item, index){
							return(<a key={index} className="d-inline-block hashtag-link" href={item.href}><div className="d-flex mb-2">#<span>{item.name}</span></div></a>)
						})
					}
				</div>
				<div className="d-flex align-items-center justify-content-between">
					<div className="d-flex justify-content-start align-items-center">
						<div className="d-flex">
							<span className="d-block comments-item-small-icons">
								<svg className="d-block w-100 h-100" focusable="false" aria-hidden="true" viewBox="0 0 24 24"><path fill="CurrentColor" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path><path fill="CurrentColor" d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path></svg>
							</span>
							<span className="comments-item-small-text">{props.datetime}</span>
						</div>
					</div>
					<div className="d-flex justify-content-end align-items-center flex-wrap">
						<div className="d-flex px-2">
							<span className="comments-item-small-text">{props.views}</span>
							<span className="d-block comments-item-small-icons">
								<svg className="d-block w-100 h-100" focusable="false" aria-hidden="true" viewBox="0 0 24 24"><path fill="CurrentColor" d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"></path></svg>
							</span>
						</div>
						<div className="d-flex px-2">
							<span className="comments-item-small-text">{props.comments}</span>
							<span className="d-block comments-item-small-icons">
								<svg className="d-block w-100 h-100" focusable="false" aria-hidden="true" viewBox="0 0 24 24"><path fill="CurrentColor" d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"></path></svg>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
