import Image from "next/image"
import {Registerlogin} from "../login"
import {CompanyName, ImageLoader, IsLogin, StaticImagesVersion} from "../../config"
import {useEffect, useState} from "react"
import {useRouter} from "next/router"

export default function Ocean(props) {

	const router = useRouter()

	const[panelButtonText, setpanelButtonText] = useState(null)
	useEffect(()=>{
		setpanelButtonText(IsLogin() ? "ورود به پنل کاربری" : "ایجاد حساب کاربری")
	}, [])

	return (
		<>
			<div className="w-100 d-flex flex-column now-account-contact-layer">
				<div className="w-100 d-flex flex-column justify-content-center align-items-center now-account-contact-box">
					<div className="container" id="now-account-contact-container">
						<div className="row d-flex justify-content-center align-items-center">
							<div className="col-md-12 d-flex flex-column justify-content-center align-items-center mb-3">
								<h2 className="h4 text-center fw-bold">
									همین الان در {CompanyName} ارز های دیجیتالی شروع به صید کنید.
								</h2>
							</div>
							<div className="col-12 col-sm-9 col-md-7 col-lg-6 col-xl-5 d-flex align-items-center now-account-contact-btns-layer">
								<div onClick={e=>Registerlogin(router)} id="second-account-button" className="register-login design-primary-btn d-inline-block px-4 py-2 c-transition mb-3 mx-3">
									{panelButtonText}
								</div>
								<div className="design-primary-btn d-inline-block px-4 py-2 c-transition mb-3 mx-3">
									تماس با پشتیبانی
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="w-100 crypto-currency-items-icon-layer">
					<div className="crypto-currency-items-icon" data-index="1"><Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/crypto-currencies/avax.png?v=" + StaticImagesVersion} alt="ارز دیجیتال آوالانچ"/></div>
					<div className="crypto-currency-items-icon" data-index="2"><Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/crypto-currencies/bnb.png?v=" + StaticImagesVersion} alt="ارز دیجیتال بایننس"/></div>
					<div className="crypto-currency-items-icon" data-index="3"><Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/crypto-currencies/link.png?v=" + StaticImagesVersion} alt="ارز دیجیتال لینک"/></div>
					<div className="crypto-currency-items-icon" data-index="4"><Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/crypto-currencies/mana.png?v=" + StaticImagesVersion} alt="ارز دیجیتال مانا"/></div>
					<div className="crypto-currency-items-icon" data-index="5"><Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/crypto-currencies/trx.png?v=" + StaticImagesVersion} alt="ارز دیجیتال ترون"/></div>
					<div className="crypto-currency-items-icon" data-index="6"><Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/crypto-currencies/shib.png?v=" + StaticImagesVersion} alt="ارز دیجیتال شیبا"/></div>
					<div className="crypto-currency-items-icon" data-index="7"><Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/crypto-currencies/btc.png?v=" + StaticImagesVersion} alt="ارز دیجیتال بیت کوین"/></div>
					<div className="crypto-currency-items-icon" data-index="8"><Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/crypto-currencies/eth.png?v=" + StaticImagesVersion} alt="ارز دیجیتال اتریوم"/></div>
				</div>
			</div>
			<div className="landscape overflow-hidden">
				<div className="mountain"></div>
				<div className="mountain mountain-2"></div>
				<div className="mountain mountain-3"></div>
				<div className="water"></div>
				<div className="splash"></div>
				<div className="splash delay-1"></div>
				<div className="splash delay-2"></div>
				<div className="splash splash-4 delay-2"></div>
				<div className="splash splash-4 delay-3"></div>
				<div className="splash splash-4 delay-4"></div>
				<div className="trash-container"></div>
				<div id="the-boat-box">
					<div className="w-100 h-100 position-relative">
						<Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/boat.png?v=" + StaticImagesVersion} alt="قایق ارز دیجیتال گیر"/>
					</div>
				</div>
				<div className="front">
					<div className="grass grass-1"></div>
					<div className="grass grass-2"></div>
				</div>
			</div>
		</>
	)
}
