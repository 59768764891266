import {useRouter} from "next/router"
import CurrencyItem from "./currency-item"
import CurrencyItemLoading from "./currency-item-loading"
import Link from "next/link"
import {IsExist} from "../../config"

export default function Currencies(props) {

	const router = useRouter()

	return (
		<section>
			<div className="container">
				<h2 className="h4 text-center fw-bold">قیمت لحظه‌ای ارز های دیجیتال</h2>
				<div className="py-3"></div>
				<div className="row d-flex justify-content-center">
					{
						IsExist(props.currencies) ?
						props.currencies.map(function(item, index){
							return (
								<CurrencyItem key={index} item={item} index={index} router={router} userFavoriteCoins={props.userFavoriteCoins}/>
							)
						})
						:
						props.dataItemsLoadingCount.map(function(item, index){
							return (
								<CurrencyItemLoading key={index}/>
							)
						})
					}
				</div>
				<div className="py-3"></div>
				<div className="d-flex justify-content-center align-items-center">
					<div className="d-inline-block px-4 py-2 c-transition design-primary-btn">
						<Link href={"/currencies"}>
							<a style={{textDecoration:"none", color:"white"}}>
								نمایش تمام ارز ها
							</a>
						</Link>
					</div>
				</div>
			</div>
		</section>
	)
}
