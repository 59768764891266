export default function CurrencyItemLoading(props) {
	return (
		<div className="cryptocurrency-info-layer col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-2 mb-3">
			<div className="c-transition w-100 px-3 py-3 d-flex flex-column cryptocurrency-info-box position-relative">
				<div className="cryptocurrency-info-icon-box">
					<div className="w-100 h-100 position-relative">
						<div className="card-loading-animation" style={{width: "40px", height: "40px"}}></div>
					</div>
				</div>
				<div className="d-flex flex-row">
					<div className="d-flex flex-row align-items-center w-100">
						<div className="d-flex justify-content-center align-items-center">
							<div className="d-block p-0 favorite-item-icon">
								<svg className="d-block w-100 h-100" viewBox="0 0 512 512"><path fill="CurrentColor" d="M0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84.02L256 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 .0003 232.4 .0003 190.9L0 190.9z"/></svg>
							</div>
						</div>
						<div className="d-flex flex-row justify-content-center align-items-center px-1">
							<div className="px-2"><div className="card-loading-animation" style={{width: "60px", height: "20px"}}></div></div>
							<div className="pt-1 sub-text-size"><div className="card-loading-animation" style={{width: "30px", height: "15px"}}></div></div>
						</div>
					</div>
				</div>
				<div>
					<span className="px-1 sub-text-size">قیمت جهانی:</span>
					<span className="px-1 fw-bold"><div className="card-loading-animation" style={{width: "40px", height: "15px"}}></div></span>
					<span className="sub-text-size">تتر</span>
				</div>
				<div>
					<span className="px-1 sub-text-size">قیمت:</span>
					<span className="px-1 fw-bold"><div className="card-loading-animation" style={{width: "70px", height: "15px"}}></div></span>
					<span className="sub-text-size">تومان</span>
				</div>
				<div className="my-1 d-flex justify-content-center align-items-center">
					<div className="cryptocurrency-info-chart-img position-relative my-1" style={{height:"60px"}}>
						<div className="card-loading-animation" style={{width: "100%", height: "50px"}}></div>
					</div>
				</div>
				<div className="d-flex align-items-center">
					<span className="px-1 sub-text-size">24 ساعت گذشته:</span>
					<div className="card-loading-animation" style={{width: "60px", height: "15px"}}></div>
				</div>
			</div>
		</div>
	)
}
