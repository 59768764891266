import Head from 'next/head'
import Template from '../components/template'
import {CompanyName, ImageLoader, IsExist, IsLogin, LoadItems, LocalStorageGetter, StaticImagesVersion, UserPanelServerUrl} from '../config'
import Image from 'next/image'
import Application from '../components/home/application'
import Forum from '../components/home/forum'
import Blog from '../components/home/blog'
import WhyUs from '../components/home/why-us'
import Ocean from '../components/home/ocean'
import Currencies from '../components/home/currencies'
import BuySell from '../components/order/buy-sell'
import {useRouter} from 'next/router'
import {useEffect, useState} from 'react'

export default function Home() {

	const router = useRouter()
	const[getItemsUrl] = useState(UserPanelServerUrl + "/Coins/Currencies")
	const[dataItems, setDataItems] = useState(null)
	const[dataItemsLoadingCount] = useState(["","","","","","","","","","","",""])
	const[userFavoriteCoins, setUserFavoriteCoins] = useState([])
	const[currencies, setCurrencies] = useState(null)

	const showForumSection = false
	const showApplicationSection = false

	useEffect(() => {
		if(IsExist(dataItems)){
			let tempCurrencies = []
			dataItems.map(function(item, index){
				if(tempCurrencies.length < 12 && userFavoriteCoins.includes(item.coinID) == true){
					tempCurrencies.push(item)
				}
			})
			dataItems.map(function(item, index){
				if(tempCurrencies.length < 12 && userFavoriteCoins.includes(item.coinID) == false){
					tempCurrencies.push(item)
				}
			})
			setCurrencies(tempCurrencies)
		}
	}, [dataItems, userFavoriteCoins])

	useEffect(() => {
		LoadItems("", getItemsUrl, setDataItems, router)
		if(IsLogin()){
			LoadItems(LocalStorageGetter("token"), (UserPanelServerUrl + "/Account/UserFavoriteCoins"), setUserFavoriteCoins, router)
		}
	}, [getItemsUrl, router])

	return (
		<>
			<Head>
				<title>صرافی {CompanyName} | خرید و فروش آسان ارز های دیجیتالی</title>
			</Head>
			<Template>
				<div className="py-3 py-sm-3 py-md-5"></div>
				<section>
					<div className="w-100 justify-content-center align-items-center header-background-image-box">
						<div className="w-100 h-100 header-background-image position-absolute">
							<Image loader={ImageLoader} layout="fill" objectFit="cover" loading="lazy" src={"/images/patern.png?v=" + StaticImagesVersion} alt='پترن'/>
						</div>
					</div>
					<div className="container position-relative">
						<div className="row d-flex justify-content-center align-items-center">
							<BuySell dataItems={dataItems} dataItemsLoadingCount={dataItemsLoadingCount}/>
							<div className="col-md-12 d-flex w-100 flex-column justify-content-center align-items-center">
								<p className="mb-0">پشتیبانی 24 ساعته حتی در روزهای تعطیل</p>
								<a href={"tel:+98413233"} className="d-flex justify-content-center align-items-center" style={{fontSize: "16px"}}>
									<div className="d-block p-2 support-number-icon ocean-loading-animation">
										<svg className="d-block w-100 h-100" viewBox="0 0 512 512"><path fill="CurrentColor" d="M18.92 351.2l108.5-46.52c12.78-5.531 27.77-1.801 36.45 8.98l44.09 53.82c69.25-34 125.5-90.31 159.5-159.5l-53.81-44.04c-10.75-8.781-14.41-23.69-8.974-36.47l46.51-108.5c6.094-13.91 21.1-21.52 35.79-18.11l100.8 23.25c14.25 3.25 24.22 15.8 24.22 30.46c0 252.3-205.2 457.5-457.5 457.5c-14.67 0-27.18-9.968-30.45-24.22l-23.25-100.8C-2.571 372.4 5.018 357.2 18.92 351.2z"/></svg>
									</div>
									<span>041-3233</span>
								</a>
							</div>
						</div>
					</div>
				</section>
				<div className="py-5"></div>
				<Currencies currencies={currencies} dataItemsLoadingCount={dataItemsLoadingCount} userFavoriteCoins={userFavoriteCoins}/>
				<div className="py-5"></div>
				<WhyUs/>
				<div className="py-5"></div>
				<Blog/>
				{
					showForumSection == false ? <></> :
					<>
						<div className="py-5"></div>
						<Forum/>
					</> 
				}
				{
					showApplicationSection == false ? <></> :
					<>
						<div className="py-5"></div>
						<Application/>
					</> 
				}
				<Ocean/>
				<style jsx global>{`
					footer{
						margin: 0px;
					}
				`}</style>
			</Template>
		</>
	)
}
