import Image from "next/image"
import {CompanyName, ImageLoader, StaticImagesVersion} from "../../config"

export default function Application(props) {
	return (
		<section>
			<div className="container">
				<div className="row d-flex justify-content-center">
					<div className="col-10 col-sm-10 col-md-6 col-lg-5 col-xl-5">
					<Image loader={ImageLoader} width={700} height={700} objectFit="cover" loading="lazy" src={"/images/app.png?v=" + StaticImagesVersion} alt={"دانلود اپلیکیشن صرافی " + CompanyName}/></div>
					<div className="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-5 d-flex align-items-center">
						<div className="d-flex flex-column px-3">
							<h2 className="h4 text-center fw-bold">نصب اپلیکیشن صرافی {CompanyName}</h2>
							<p className="text-center">برای کارکرد بهتر بر روی دستگاه های اندرویدی لطفا اپلیکیشن صرافی را نصب کنید.</p>
							<div className="py-2"></div>
							<div className="w-100 d-flex flex-column justify-content-center align-items-center">
								<div className="mb-3 px-3 py-3 d-flex flex-column download-application-btn-layer c-transition">
									<div className="d-flex flex-row">
										<div className="d-flex flex-row align-items-center w-100 justify-content-between">
											<div className="d-flex flex-column px-2 fw-bold" style={{whiteSpace: "nowrap"}}>کافه بازار</div>
											<div className="download-market-icon position-relative">
												<Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/bazaar.png?v=" + StaticImagesVersion} alt="کافه بازار"/>
											</div>
										</div>
									</div>
								</div>
								<div className="mb-3 px-3 py-3 d-flex flex-column download-application-btn-layer c-transition">
									<div className="d-flex flex-row">
										<div className="d-flex flex-row align-items-center w-100 justify-content-between">
											<div className="d-flex flex-column px-2 fw-bold" style={{whiteSpace: "nowrap"}}>گوگل پلی استور</div>
											<div className="download-market-icon position-relative">
												<Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/play-store.png?v=" + StaticImagesVersion} alt="گوگل پلی استور"/>
											</div>
										</div>
									</div>
								</div>
								<div className="mb-3 px-3 py-3 d-flex flex-column download-application-btn-layer c-transition">
									<div className="d-flex flex-row">
										<div className="d-flex flex-row align-items-center w-100 justify-content-between">
											<div className="d-flex flex-column px-2 fw-bold" style={{whiteSpace: "nowrap"}}>دریافت مستقیم</div>
											<div className="download-market-icon position-relative">
												<Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/android.png?v=" + StaticImagesVersion} alt="دریافت مستقیم"/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
