import {CompanyName, InitSiema, IsExist} from "../../config"
import Comment from "../comment"
import {useEffect, useState} from "react"

export default function Forum(props) {

	const[sliderItems] = useState([
		{
			"src":"",
			"title":"",
			"description":"",
			"tags":[{"href":"", "name":"صرافی"},{"href":"","name":"صرافی"}],
			"datetime":"2 ساعت پیش",
			"views":"76k",
			"comments":"350",
			"href":""
		},
		{
			"src":"",
			"title":"",
			"description":"",
			"tags":[{"href":"", "name":"صرافی"},{"href":"","name":"صرافی"}],
			"datetime":"2 ساعت پیش",
			"views":"76k",
			"comments":"350",
			"href":""
		},
		{
			"src":"",
			"title":"",
			"description":"",
			"tags":[{"href":"", "name":"صرافی"},{"href":"","name":"صرافی"}],
			"datetime":"2 ساعت پیش",
			"views":"76k",
			"comments":"350",
			"href":""
		},
		{
			"src":"",
			"title":"",
			"description":"",
			"tags":[{"href":"", "name":"صرافی"},{"href":"","name":"صرافی"}],
			"datetime":"2 ساعت پیش",
			"views":"76k",
			"comments":"350",
			"href":""
		},
		{
			"src":"",
			"title":"",
			"description":"",
			"tags":[{"href":"", "name":"صرافی"},{"href":"","name":"صرافی"}],
			"datetime":"2 ساعت پیش",
			"views":"76k",
			"comments":"350",
			"href":""
		},
	])

	const[slideItemsFinal , setSlideItemsFinal] = useState(null)
	useEffect(() => {
		if(IsExist(slideItemsFinal)){
			InitSiema(".siema", 3000, true, {'100': 1,'576': 2,'1200': 3})
		}else{
			setSlideItemsFinal(sliderItems)
			document.getElementById("siema").innerHTML = ""
		}
	}, [slideItemsFinal, sliderItems])

	return (
		<section>
			<div className="container">
				<div className="row d-flex justify-content-center pt-4 px-4 mx-1 forom-layer">
					<div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 mb-4 d-flex flex-column justify-content-center align-items-center">
						<h2 className="h4 text-center fw-bold">تالار گفتگو</h2>
						<p className="text-center mb-3">در تالار گفتگوی صرافی {CompanyName} با افراد فعال در حوزه ارز های دیجیتالی به بحث و گفتگو بپردازید.</p>
						<div className="w-100 d-flex align-items-center blog-category-btns-layer">
							<div className="blog-category-btn c-transition" data-index="1">کیف پول</div>
							<div className="blog-category-btn c-transition" data-index="2">صرافی</div>
							<div className="blog-category-btn c-transition" data-index="3">تحلیل</div>
							<div className="blog-category-btn c-transition" data-index="4">ماینینگ</div>
							<div className="blog-category-btn c-transition" data-index="5">سرمایه</div>
							<div className="blog-category-btn c-transition" data-index="6">اخبار</div>
							<div className="blog-category-btn c-transition" data-index="7">معاملات</div>
							<div className="blog-category-btn c-transition" data-index="8">بحث آزاد</div>
							<div className="blog-category-btn c-transition" data-index="9">ترید</div>
						</div>
						<div className="py-2"></div>
						<div className="d-flex justify-content-center align-items-center">
							<div className="d-inline-block px-4 py-2 c-transition design-primary-btn">
								ورود به تالار
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 mb-4 d-flex justify-content-center align-items-center forom-items-layer">
						<div className="w-100">
							<div id="siema" className="siema">
								{
									slideItemsFinal == null ? "" : 
										slideItemsFinal.map(function(item, index){
											return(
												<Comment key={index} 
														 title={item.title} 
														 description={item.description}
														 tags={item.tags}
 														 datetime={item.datetime}
														 views={item.views}
														 comments={item.comments}
														 href={item.href}/>
											)
										})
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
