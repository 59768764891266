import Image from "next/image"
import {CompanyName, ImageLoader, StaticImagesVersion} from "../../config"

export default function WhyUs(props) {

	let whyuses = [
		{
			"title": "انجام فوری سفارشات",
			"description": "سفارشات خرید و فروش بعد از ثبت نهایی در کمترین زمان ممکن و به صورت آنی انجام می‌شوند.",
			"src": "/images/why-us/fast.png",
		},
		{
			"title": "آسان بودن خرید و فروش",
			"description": "سیستم خرید و فروش به صورتی طراحی شده است که کاربران به راحت ترین حالت ممکن خرید و فروش خود را انجام دهند.",
			"src": "/images/why-us/easy.png",
		},
		{
			"title": "امنیت صرافی",
			"description": "امنیت یکی از فاکتورهای اساسی است که توسط تیم متخصص به صورت دوره‌ای بررسی می‌شود.",
			"src": "/images/why-us/safe.png",
		},
	]

	return (
		<section>
			<div className="container">
				<div className="row d-flex justify-content-center">
					<div className="order-2 order-sm-2 order-md-1 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
						<h2 className="h4 fw-bold">چرا صرافی {CompanyName} را انتخاب کنیم؟</h2>
						<p className="h6 py-3 mb-2" style={{textAlign:"justify"}}>هدف اصلی ما نهایت فوری، آسان و امن بودن سفارشات شماست.</p>
						{
							whyuses.map(function(item, index){
								return (
									<div key={index} className="d-flex flex-row mb-3">
										<div className="whyus-item-icon-box">
											<div className="w-100 h-100 position-relative">
												<Image loader={ImageLoader} layout="fill" objectFit="fill" loading="lazy" src={item.src+ "?v=" + StaticImagesVersion} alt={item.title}/>
											</div>
										</div>
										<div className="d-flex flex-row align-items-center justify-content-between" style={{width: "calc(100% - 100px)"}}>
											<div className="d-flex flex-column" style={{paddingRight: "12px"}}>
												<h4 className="h5">{item.title}</h4>
												<p className="mb-0 whyus-item-description" style={{textAlign:"justify"}}>{item.description}</p>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
					<div className="order-1 order-sm-1 order-md-2 col-10 col-sm-10 col-md-6 col-lg-6 col-xl-5 col-xxl-5 d-flex justify-content-center align-items-center mb-3">
						<Image loader={ImageLoader} width={640} height={450} objectFit="fill" loading="lazy" src={"/images/why-us/main.png?v=" + StaticImagesVersion} alt={"چرا صرافی " + CompanyName + "را انتخاب کنیم؟"}/>
					</div>
				</div>
			</div>
		</section>
	)
}
